var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.tableLoading,
          expression: "tableLoading",
        },
      ],
      staticClass: "app",
    },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "系统应用对比分析",
          showIcon: false,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-export": _vm.headExport },
      }),
      _c(
        "div",
        { staticClass: "gridTable" },
        [
          _c(
            "el-table",
            {
              staticClass: "table_style",
              staticStyle: { width: "100%" },
              attrs: {
                "cell-class-name": _vm.addClass,
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                height: "100%",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "contractName",
                  fixed: "left",
                  label: "执行单位",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "projectName",
                  fixed: "left",
                  label: "项目名称",
                  width: "200",
                },
              }),
              _vm._l(_vm.tableColData, function (item) {
                return _c(
                  "el-table-column",
                  {
                    key: item.name,
                    attrs: {
                      width: "180px",
                      prop: item.field,
                      label: item.title,
                    },
                  },
                  _vm._l(item.children, function (item1) {
                    return _c("el-table-column", {
                      key: item1.id,
                      attrs: {
                        width: "180px",
                        prop: item1.field,
                        label: item1.title,
                      },
                    })
                  }),
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }